.packex-pagination-container {
  .pagination-items-per-page {

    .pagination-items-per-page-label {
      padding-right: 1rem;
      min-width: 121px;
      vertical-align: middle;
    }

    .mat-mdc-form-field {
      max-width: 2.875rem;
    }

    .mat-mdc-form-field-infix, .mdc-text-field {
      padding: 0 !important;
    }

    .mdc-line-ripple {
      display: block;
    }

    .mat-mdc-form-field-infix {
      min-height: auto;
    }

    .mat-mdc-select-value {
      display: flex;
    }

    .mat-mdc-select-value-text {
      font-size: 0.813rem;
      padding: .5rem;
    }

    .mdc-line-ripple::before {
      bottom: 10px;
    }

    .mdc-line-ripple::after {
      border: none;
    }

    .mat-mdc-select-arrow-wrapper {
      height: 20px;
    }
  }
}
